import React, { FC } from 'react';

import classNames from 'classnames';

import styles from './PlusStatsBlock.css';
import { ListCheckCircleIcon } from '../../../../../../../FigmaStyleguide/Icons/ListCheckCircleIcon';
import { SubscriptionActions } from '../../PlusPaymentAndSubscription';

interface StatsBlockProps {
  type: SubscriptionActions;
  isTrialActive: boolean;
  isAASubscription: boolean;
}

const COLOR_CIRCLE_COLOR = '#FFFFFF';
const COLOR_CIRCLE_MAIN = '#12B76A';

export const PlusStatsBlock: FC<StatsBlockProps> = React.memo(({
  type,
  isTrialActive,
  isAASubscription
}: StatsBlockProps) => {
  const listItems =
    isTrialActive || isAASubscription
      ?
      [
        '3 Day Free Trial*',
        'No Disruptive Ads',
        'Exclusive Arkadium Games',
        'Subscriber-Only Games',
        'Bonus Gems',
        'Save 30% on Gems',
        'VIP Support'
      ]
      :
      [
        'No Disruptive Ads',
        'Exclusive Arkadium Games',
        'Subscriber-Only Games',
        'Bonus Gems',
        'Save 30% on Gems',
        'VIP Support'
      ];


  return (
    <div className={classNames(styles.container, { [styles.restart]: type === SubscriptionActions.RESTART })}>
      <ul className={styles.list}>
        {listItems.map((i) => (
          <div key={i} className={styles.listContainer}>
            <ListCheckCircleIcon
              checkColor={COLOR_CIRCLE_COLOR}
              circleColor={COLOR_CIRCLE_MAIN} width={20}
              height={20}
            />
            <li>{i}</li>
          </div>
        ))}
      </ul>
    </div>
  );
});
