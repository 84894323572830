import React, { FC, useEffect, useState } from 'react';

import { batch, useDispatch, useSelector } from 'react-redux';

import { MiscUtils } from '../../utils/MiscUtils';
import { environment } from '../config/environment';
import { HeaderSideMenuTabs } from '../constants/HeaderSideMenuTabs';
import { PageTypes } from '../constants/Pages';
import { SS_ARK_PROMO_CODE } from '../constants/PromoCodes';
import { LS_COOKIE_CONSTS, QUERY_STRING_CONSTS } from '../models/Enums';
import { CoBrandedPageSEO } from '../models/PagesData';
import { sweeptakesPromoCookieSet } from '../organisms/CategoryPromo/CategoryPromo';
import { FingerprintService } from '../services/Analytics/FingerprintJS/FingerprintService';
import { getLeanplum, LeanplumAnalytics } from '../services/Analytics/LeanplumAnalytics';
import {
    lpAsyncSetEmailConsent,
    lpAsyncSetUserAttributes,
    lpAsyncStartOrUpdateSession,
    lpHandleSubscriptions,
} from '../services/Analytics/LeanplumAnalyticsHelpers';
import { CookieService } from '../services/CookieService';
import { setAiIsVirtualItemBuy, setAiIsVirtualItemSpent } from '../services/GemsService';
import { PageService } from '../services/PageService';
import { PWAService } from '../services/PWAService';
import { UrlService } from '../services/UrlService';
import UserService from '../services/UserService';
import { setSideMenuActivePage, setSideMenuOpened } from '../store/ducks/layout';
import { GemsAnalyticsShopLocations, setGemsShopLocation } from '../store/ducks/leanplum/lpAnalytics';
import { setStepIndex } from '../store/ducks/subscription/common';
import { GemsEffects } from '../store/effects/gems.effects';
import { AppBody } from './AppBody';
import { AppBodySubscription } from './subscription/AppBodySubscription';
import { ToastBanner, ToastBannerProps } from './toastBanner/ToastBanner';
import { EnvironmentName } from '../constants/Environment';
import CleverTapService from '../services/CleverTapService';
import { LeanplumCarouselSlide, setLeanplumCarouselData } from '../store/ducks/leanplum/homepageCarousel';

export enum QueryParamConstants {
    OPEN_TAB = 'menuTab',
    OPEN_SHOP = 'Shop',
    OPEN_HELP = 'Help',
}

export const AppBodyWrapper: FC = React.memo(() => {
    // CobrandedPage.tsx contains old version for URLs like www.ark.com/gazette/?affiliate=1
    // New version based on query value ?affiliate_id=gazette and apply for all pages
    const pages = useSelector((state) => state.pages);
    const pageType = useSelector((state) => state.pageType);
    const pathname = useSelector((state) => state.router.location.pathname);
    const slug = UrlService.getSlugFromPathname(pathname);
    const user = useSelector((state) => state.user);
    const activeUserSubscriptions = useSelector((state) => state.activeUserSubscriptions);
    const expiredUserSubscriptions = useSelector((state) => state.expiredUserSubscriptions) || null;
    const userProcessed = useSelector((state) => state.userProcessed);
    const gemsAmount = useSelector((state) => state.gemsAmount);
    const dispatch = useDispatch();
    const Leanplum = getLeanplum();
    const emailConsent = user && Boolean(user?.emailRetrievalConsent);
    const isServer = MiscUtils.isServer;
    const arkConfigData = useSelector((state) => state.arkConfig);
    const [toastBannerOpen, setToastBannerOpen] = useState<boolean>(false);
    const [toastBannerData, setToastBannerData] = useState<ToastBannerProps>({
        title: '',
        desktopBackground: '',
        onClose: undefined,
    });

    if (!MiscUtils.isServer) {
        const affiliateQueryUrl = UrlService.getQSParam(location.search, QUERY_STRING_CONSTS.ARK_AFFILIATE_ID);

        if (affiliateQueryUrl) {
            const cookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.ARK_AFFILIATE);

            if (cookie !== affiliateQueryUrl) {
                CookieService.setArkCookie(
                    LS_COOKIE_CONSTS.ARK_AFFILIATE,
                    affiliateQueryUrl,
                    LS_COOKIE_CONSTS.ARK_AFFILIATE_STORE_DAYS
                );
            }
        } else if (pageType === PageTypes.Dynamic) {
            // to support old affiliate URLs like www.ark.com/gazette/?affiliate=1
            // start
            const pageSEO = PageService.getPageSEOByPageName(pages, slug) as CoBrandedPageSEO;

            if (pageSEO?.cookieValue) {
                const cookieValue = pageSEO.cookieValue;
                const affiliateQueryUrlOld = UrlService.getQSParam(location.search, QUERY_STRING_CONSTS.ARK_AFFILIATE);

                if (affiliateQueryUrlOld) {
                    const cookie = CookieService.getArkCookie(LS_COOKIE_CONSTS.ARK_AFFILIATE);

                    if (cookie !== cookieValue) {
                        CookieService.setArkCookie(
                            LS_COOKIE_CONSTS.ARK_AFFILIATE,
                            cookieValue,
                            LS_COOKIE_CONSTS.ARK_AFFILIATE_STORE_DAYS
                        );
                    }
                }
            }
            // end
        }
    }

    /* AI global virtualItemBuy, Fingerprint CD-s setup */
    useEffect(() => {
        const isDevelopmentMode =
            environment.Name !== EnvironmentName.PRODUCTION && environment.Name !== EnvironmentName.CANARY;

        setAiIsVirtualItemBuy();
        setAiIsVirtualItemSpent();
        FingerprintService.init(isDevelopmentMode);
    }, [user]);

    /* SWEEPTAKES REFRESH TEST */
    useEffect(() => {
        if (window?.location?.search?.match?.(/sweeptakesRefreshTest/gi)?.length) {
            sweeptakesPromoCookieSet(true);
        }
    }, []);

    /* LEANPLUM PROCEEDING START */
    /**/

    CleverTapService.onFetchVariables(() => {
        let homepageCarouselSlides: LeanplumCarouselSlide[] = [];
        const variables = CleverTapService.groupedVariables;

        for (const key in variables) {
            if (key.includes('HomePageSlide')) {
                homepageCarouselSlides.push(variables[key] as LeanplumCarouselSlide);
            }
        }

        homepageCarouselSlides.length && dispatch(setLeanplumCarouselData(homepageCarouselSlides));
    })

    const userUid = user?.uid;

    /**/
    useEffect(() => {
        setTimeout(
            () => {
                const onShowMessage = lpAsyncStartOrUpdateSession(
                    userUid,
                    arkConfigData?.lpDelayWakeUp,
                    setToastBannerData,
                    setToastBannerOpen
                );

                return () => Leanplum?.off('showMessage', onShowMessage);
            },
            0
        );
    }, [userUid]);
    /**/
    useEffect(() => {
        lpHandleSubscriptions(dispatch, userProcessed, expiredUserSubscriptions);
    }, [
        lpHandleSubscriptions,
        user,
        userProcessed,
        activeUserSubscriptions,
        expiredUserSubscriptions,
        gemsAmount,
        dispatch,
    ]);
    /**/
    useEffect(() => {
        if (user && !LeanplumAnalytics.startFirstRunFlag) {
            // flag is set after handler proceeded once
            lpAsyncSetUserAttributes();
            lpAsyncSetEmailConsent(emailConsent);
        }

        IsUserLoggedIn();
    }, [lpAsyncSetUserAttributes, lpAsyncSetEmailConsent, user, emailConsent]);
    /**/
    /* LEANPLUM PROCEEDING END */

    useEffect(() => {
        IsUserLoggedIn();
    }, [dispatch]);

    const IsUserLoggedIn = () => {
        if (UserService.isUserLoggedIn()) {
            batch(() => {
                dispatch(GemsEffects.UpdateGemsAmount());
                dispatch(GemsEffects.UpdatePriceSkipPreroll());
            });
        }
    };

    /* CUSTOM EVENTS HANDLING */
    useEffect(() => {
        window.addEventListener('message', handleCustomEvent);
        return () => window.removeEventListener('message', handleCustomEvent);
    }, []);

    function handleCustomEvent(event: any) {
        const { type, payload } = event.data || {};

        switch (type) {
            case 'ACTION_OPEN_MENU_TAB':
                batch(() => {
                    dispatch(setSideMenuOpened(true));

                    if (payload && Object.values(HeaderSideMenuTabs).includes(payload)) {
                        dispatch(setSideMenuActivePage(payload));
                    }
                });
                break;
            case 'ACTION_CLOSE_MENU_TAB':
                dispatch(setSideMenuOpened(false));
                break;
            default:
                break;
        }
    }

    const pagePath = window.location.pathname;
    const search = window.location.search;
    const searchTab = UrlService.getQSParam(search, QueryParamConstants.OPEN_TAB);
    const searchShop = window.location.search.match(
        new RegExp(`${QueryParamConstants.OPEN_TAB}=${QueryParamConstants.OPEN_SHOP}`, 'gi')
    );
    const searchHelp = window.location.search.match(
        new RegExp(`${QueryParamConstants.OPEN_TAB}=${QueryParamConstants.OPEN_HELP}`, 'gi')
    );
    let targetTab;

    if (searchShop) {
        targetTab = HeaderSideMenuTabs.SHOP_TAB;
    } else if (searchHelp) {
        targetTab = HeaderSideMenuTabs.HELP_TAB;
    } else if (searchTab) {
        const enumKey = Object.keys(HeaderSideMenuTabs).filter(
            (tabName) => HeaderSideMenuTabs[tabName].toString().toLowerCase() === searchTab.toLowerCase()
        )?.[0];

        targetTab = enumKey ? HeaderSideMenuTabs[enumKey] : null;
    }

    PWAService.pwaOpenOs();

    useEffect(() => {
        if (!isServer) {
            if (targetTab) {
                batch(() => {
                    dispatch(setSideMenuOpened(true));
                    dispatch(setSideMenuActivePage(targetTab));

                    if (searchShop && targetTab && targetTab === HeaderSideMenuTabs.SHOP_TAB) {
                        dispatch(setGemsShopLocation(GemsAnalyticsShopLocations.URL_SEARCH_PARAM));
                    }

                    // to reset step after another purchase finished
                    dispatch(setStepIndex(0));
                });
            } else {
                dispatch(setStepIndex(0));
            }

            window.sessionStorage.removeItem(SS_ARK_PROMO_CODE);
        }
    }, [dispatch, isServer, pagePath, search, searchTab, searchShop, targetTab]);

    return (
        <>
            {pageType && pageType === PageTypes.Subscription ? <AppBodySubscription /> : <AppBody />}
            {toastBannerOpen && <ToastBanner {...toastBannerData} />}
        </>
    );
});
