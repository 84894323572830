import React, { FC } from 'react';

import styles from './IconsBlock.css';
import { AdsIcon } from '../../../../../FigmaStyleguide/Icons/AdsIcon';
import { CustomAvatar } from '../../../../../FigmaStyleguide/Icons/CustomAvatars';
import { DiscountIcon } from '../../../../../FigmaStyleguide/Icons/DiscountIcon';

export const IconsBlock: FC = React.memo(() => {
  return (
    <div className={styles.container}>
      <div className={styles.point}>
        <div className={styles.iconBlock} />
        <AdsIcon className={styles.icon} />
        No disruptive ads
      </div>
      <div className={styles.point}>
        <div className={styles.iconBlock} />
        <DiscountIcon className={styles.icon} />
        15% discount on purchases
      </div>
      <div className={styles.point}>
        <div className={styles.iconBlock} />
        <CustomAvatar className={styles.icon} />
        Subscriber-only avatars
      </div>
    </div>
  );
});
