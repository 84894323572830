import React, { FC } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import styles from './StatusBlock.css';
import { environment } from '../../../../../config/environment';
import { MonthsShort } from '../../../../../constants/Months';
import { Button } from '../../../../../FigmaStyleguide/Button/Button';
import { Analytics } from '../../../../../services/Analytics/Analytics';

interface StatusBlockProps {
  date: string;
  planId: string;
  isTrialActive?: boolean;
}

export const StatusBlock: FC<StatusBlockProps> = React.memo(({ date, planId, isTrialActive }: StatusBlockProps) => {
  const hostedLoginToken = useSelector((state) => state.hostedLoginToken);
  const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);
  const d = new Date(date);
  const dateNum = d.getDate();
  const month = MonthsShort[d.getMonth()];
  const year = d.getFullYear();
  const manageSubscription = () => {
    Analytics.trackEvent(Analytics.subscription.manageSubscription());
    setTimeout(() => window.open(`${environment.RECURLY_BILLING_PAGE}${hostedLoginToken}`, '_blank'), 300);
  };

  return (
    <div className={styles.container}>
      <div className={styles.textBlock}>
        <p>
          {isTrialActive ? `Your trial ends: ` : `Your subscription ends: `}
          <span>{month} {dateNum}, {year}</span>
        </p>
        <p>
          {isTrialActive ? `Your trial plan: ` : `Your subscription plan: `}
          <span className={styles.plan}>{planId.includes('monthly') ? 'Monthly' : 'Annual'}</span>
        </p>
      </div>
      <Button
        disabled={!hostedLoginToken}
        className={classNames(styles.btnSubscribe, { [styles.btnSubscribePlus]: plusFeatureFlag })}
        onClick={manageSubscription}
      >
        Manage {isTrialActive ? 'trial' : 'subscription'}
      </Button>
    </div>
  );
});
