import { AnalyticsEventBuilder } from './Analytics/AnalyticsEventBuilder';
import { LocalStorageService } from './LocalStorage';
import { UrlService } from './UrlService';
import UserService from './UserService';
import { MiscUtils } from '../../utils/MiscUtils';
import { environment } from '../config/environment';
import { EnvironmentName } from '../constants/Environment';
import { LS_COOKIE_CONSTS } from '../models/Enums';

export class AppInsightService {
    private static customDimensionsAppinsights: {};
    private static pingInterval = 60000;
    private static lastEventSent = false;
    private static timer: any = null;

    static init() {
        this.trackPageView();
        this.setupPingTracking();
    }

    private static setupPingTracking() {
        this.timer = setInterval(() => {
            if (!this.lastEventSent) {
                this.trackPingEvent();
            }

            this.lastEventSent = false;
        }, this.pingInterval);
    }

    private static async trackPingEvent() {
        let options= {
            gameSlug: "",
            gameRelease: "",
            gameVersion: "",
            sdkVersion: "",
            isFavorited: false
        }

        if (window && window.aiGameOptions) {
            options.gameSlug = window.aiGameOptions.game;
            options.gameRelease = window.aiGameOptions.gameRelease;
            options.gameVersion = window.aiGameOptions.gameVersion;
            options.sdkVersion = window.aiGameOptions.sdkVersion;
            options.isFavorited = window.aiGameOptions.isFavorited;
        }

        this.trackEvent('alive', {
            message: 'User is alive',
            timestamp: new Date().toISOString(),
            uri: UrlService.convertEntities(window.location.href),
            pagePath: window.location.pathname,
            screenResolution: `${window.innerWidth}x${window.innerHeight}`,
            noninteraction: typeof window.userActive === 'boolean' && !window.userActive,
            targetingConsent: LocalStorageService.getItem(LS_COOKIE_CONSTS.TARGETING_CONSENT),
            environment: EnvironmentName[environment.Name]?.toLowerCase(),
            gameplayID: window.arkCurrentPlayId ?? '',
            ...options,
            ...this.customDimensionsAppinsights,
            ...(await new AnalyticsEventBuilder().baseEvent()).options,
            ...this.customDimensionsAppinsights,
            ...(await new AnalyticsEventBuilder().pageViewEvent()).options
        });
    }

    static trackEvent(name: string, data: any) {
        if ((window as any).appInsightsArkadium) {
            (window as any).appInsightsArkadium.trackEvent({
                name,
                properties: { ...data, pagePath: window.location.pathname },
            });
            this.lastEventSent = true;
        }
    }

    static trackAppError(error: any, info = {}, isErrorBoundary = false) {
        const problemPagePath = window ? window.location.pathname : undefined;

        this.trackEvent('js-error', {
            error,
            env: BUILD_ENV,
            info: JSON.stringify(info),
            page: problemPagePath,
            adBlockEnabled: MiscUtils.isAdBlockEnabled(),
            isUserLoggedIn: UserService.isUserLoggedIn(),
            isUserSubscribed: UserService.isUserSubscriber(),
            type: isErrorBoundary ? 'Something went wrong' : 'common',
            ...this.customDimensionsAppinsights,
        });
    }

    static async trackPageView() {
        if ((window as any).appInsightsArkadium) {
            (window as any).appInsightsArkadium.trackPageView({
                name: document.title,
                uri: UrlService.convertEntities(window.location.href),
                properties: {
                    ...(await new AnalyticsEventBuilder().pageViewEvent()).options,
                    pagePath: window.location.pathname,
                },
                screenResolution: window.innerWidth + 'x' + window.innerHeight,
                ...this.customDimensionsAppinsights,
            });
            this.lastEventSent = true;
        }
    }

    public static setCustomDimensions(customDimensions: any) {
        this.customDimensionsAppinsights = {
            ...this.customDimensionsAppinsights,
            ...customDimensions,
        };
    }
}
