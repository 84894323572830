import React, { FC, useEffect, useState } from 'react';

import { UserSubscription } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/subscription.dto';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from './ManagePlan.css';
import { globalErrorHandler } from '../../../../../utils/LogUtils';
import { I18nText } from '../../../../atoms/i18nText/i18nText';
import { NavLink } from '../../../../atoms/Link/Link';
import { SubscriptionPlans } from '../../../../constants/SubscriptionPlan';
import { Button } from '../../../../FigmaStyleguide/Button/Button';
import subsStyles from '../../../../molecules/Subscription/SubscriptionStyles.css';
import { Analytics } from '../../../../services/Analytics/Analytics';
import { AppInsightService } from '../../../../services/AppInsight';
import PaymentService from '../../../../services/PaymentService';
import { UrlService } from '../../../../services/UrlService';
import UserService from '../../../../services/UserService';
import { setSnackbarData } from '../../../../store/ducks/layout';
import { setActiveUserSubscriptions } from '../../../../store/ducks/subscription/common';

export enum ManageType {
  MANAGE,
  UPDATE,
  CANCEL,
}

export const ManagePlan: FC = React.memo(() => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [manageType, setManageType] = useState<ManageType>(ManageType.MANAGE);
  /* Get Subscription Type from some storage */
  const [subsPlan, setSubsPlan] = useState<string>(SubscriptionPlans.MONTHLY);
  const [expiredDate, setExpiredDate] = useState<string>('');
  const [activeSubscriptionId, setActiveSubscriptionId] = useState<string>('');
  const [subscriptionIsAutoRenewable, setSubscriptionIsAutoRenewable] = useState(null);
  const activeSubscriptionFromRedux = useSelector(({ activeUserSubscriptions }) => activeUserSubscriptions || []);

  useEffect(() => {
    if (activeSubscriptionFromRedux?.length === 0) {
      PaymentService.getSubscriptions()
        .then((res) => {
          dispatch(setActiveUserSubscriptions(res));

          if (res.length !== 0) {
            setStatePlan(res);
          }
        })
        .catch((err) => {
          globalErrorHandler({ error: err, filename: 'ManagePlan.tsx', info: 'PaymentService.getSubscriptions()' });
          AppInsightService.trackAppError(err, { data: 'ManagePlan' });
        });
    } else {
      setStatePlan(activeSubscriptionFromRedux);
    }
  }, []);

  // SEt UP all params for layout
  function setStatePlan(subscriptions: UserSubscription[]) {
    const LAST_SUBSCRIPTION = PaymentService.getLastDateSubscription(subscriptions);
    const { endDate, subscriptionId, isAutoRenewable } = LAST_SUBSCRIPTION;
    // Maybe install some library dateFormatter or smth?
    const ENJOY_UP_DATE = new Date(endDate).toLocaleString('en-US', {
      day: 'numeric',
      year: 'numeric',
      month: 'long'
    });

    setActiveSubscriptionId(subscriptionId);
    setExpiredDate(ENJOY_UP_DATE);
    setSubscriptionIsAutoRenewable(isAutoRenewable);
  }

  useEffect(() => {
    const subsPlanParam = UrlService.getQSParam(window.location.search, 'subsPlan');

    if (subsPlanParam === SubscriptionPlans.MONTHLY) {
      setSubsPlan(SubscriptionPlans.MONTHLY);
    } else {
      setSubsPlan(SubscriptionPlans.ANNUAL);
    }
  }, []);

  const handlePurchase = (value: boolean) => {
    /** true/false for Annual purchase */
    if (value) {
      if (subsPlan === SubscriptionPlans.ANNUAL) {
        alert('Changed to Monthly');
      } else {
        alert('Changed to Annual');
      }
    } else {
      setManageType(ManageType.MANAGE);
    }
  };
  const handleCancel = (value: boolean) => {
    /** true/false for Cancel purchase */
    if (value) {
      subscriptionIsAutoRenewable
        ? PaymentService.cancelSubscription(activeSubscriptionId)
          .then(() => {
            setSubscriptionIsAutoRenewable(false);
            dispatch(
              setSnackbarData({
                isOpened: true,
                message: 'Your subscription has been canceled.',
                type: 'success'
              })
            );
            void Analytics.trackEvent(Analytics.subscription.cancelAutorenewal());
            PaymentService.getSubscriptions()
              .then((res) => {
                dispatch(setActiveUserSubscriptions(res));
              })
              .catch((err) => {
                AppInsightService.trackAppError(err, {
                  data: 'cancelSubscription-GetSubscription()'
                });
              });
            UserService.closePanel();
          })
          .catch((err) => {
            dispatch(
              setSnackbarData({
                isOpened: true,
                message: err.body.details[0].Message,
                type: 'error'
              })
            );
            AppInsightService.trackAppError(err, { data: 'handleCancel()' });
          })
        : dispatch(
          setSnackbarData({
            isOpened: true,
            message: 'Your subscription has already been canceled.',
            type: 'error'
          })
        );
    } else {
      setManageType(ManageType.MANAGE);
    }
  };
  const handlerCancelSubscription = () => {
    setManageType(ManageType.CANCEL);
  };
  const getHeading = () => {
    switch (manageType) {
      case ManageType.MANAGE:
        return t('PLAN.HEADING_MANAGE');
      case ManageType.UPDATE:
        if (subsPlan === SubscriptionPlans.MONTHLY) {
          return t('PLAN.HEADING_UPDATE_SUBSCRIPTION');
        }

        return t('PLAN.HEADING_DOWNGRADE_SUBSCRIPTION');
      case ManageType.CANCEL:
        return t('PLAN.HEADING_CANCELLATION');
      default:
        return null;
    }
  };
  const getBottomRow = () => {
    switch (manageType) {
      case ManageType.MANAGE:
        return (
          <div className={styles.bottomRow}>
            <div className={styles.subHeading}>{t('PLAN.SUB_HEADING_CANCEL_SUBS')}</div>
            <div className={styles.enjoyRow}>
              {t('PLAN.ENJOY_TEXT_ADVANTAGE')}&nbsp;<strong>{expiredDate}</strong>
            </div>
            <div>
              <Button type="button" className={styles.cancelBtn} onClick={handlerCancelSubscription}>
                {t('PLAN.CANCEL_SUBSCRIPTION')}
              </Button>
            </div>
          </div>
        );
      case ManageType.UPDATE:
        return (
          <div className={styles.confirmBlock}>
            <div className={styles.confirmBtnsRow}>
              <Button linkOnMobile outlined onClick={() => handlePurchase(false)}>
                {t('PLAN.CHANGED_MY_MIND')}
              </Button>
              <Button className={styles.confirmBtn} onClick={() => handlePurchase(true)}>
                {t('PLAN.CONFIRM_PURCHASE')}
              </Button>
            </div>
            <div className={styles.confirmText}>
              <p>
                {t('PLAN.ARKADIUM_CONFIRMATION')}&nbsp;
                <NavLink to="/legacy-documents/arkadium-player-agreement.pdf" target="_blank" rel="noopener">
                  <I18nText keyName="PLAYER_AGREEMENT" />
                </NavLink>{','}&nbsp;
                <NavLink to="arkadium-advantage-agreement.pdf" target="_blank" rel="noopener">
                  <I18nText keyName="SUBSCRIPTION_AGREEMENT" />
                </NavLink>&nbsp;
                {t('PLAN.AND')}
                <span className={subsStyles.link}> </span>
                <NavLink to={UrlService.createURL('privacy-policy')} target="_blank" rel="noopener">
                  <I18nText keyName="FOOTER_PRIVACY_POLICY" />
                </NavLink>&nbsp;
              </p>
            </div>
          </div>
        );
      case ManageType.CANCEL:
        return (
          <>
            <div className={classNames(styles.confirmText, styles.cancelText)}>
              {t('PLAN.SURE_CANCEL_ADVANTAGE')}
            </div>
            <div className={classNames(styles.confirmBtnsRow, styles.cancelBtnsRow)}>
              <Button outlined onClick={() => handleCancel(true)}>
                {t('PLAN.CANCEL_SUBSCRIPTION')}
              </Button>
              <Button className={styles.confirmBtn} onClick={() => handleCancel(false)}>
                {t('PLAN.CHANGED_MY_MIND')}
              </Button>
            </div>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.mainHeading}>{getHeading()}</div>
        {getBottomRow()}
      </div>
    </>
  );
});
