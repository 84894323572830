import React, { useEffect, useRef, useState } from 'react';

import Box from '@mui/material/Box';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import styles from './Footer.css';
import { Button } from '../../atoms/Buttons/Base/Base';
import { I18nText } from '../../atoms/i18nText/i18nText';
import { ArkadiumFullLogo } from '../../atoms/Icons/Styleguide/ArkadiumLogo/ArkadiumLogo';
import { FacebookFooterIcon } from '../../atoms/Icons/Styleguide/FacebookFooterIcon';
import { LinkedInIcon } from '../../atoms/Icons/Styleguide/LinkedInIcon';
import { MadeInNYIcon } from '../../atoms/Icons/Styleguide/MadeInNYIcon';
import { SocialIcon, SocialIconType } from '../../atoms/Icons/Styleguide/SocialIcon';
import { Responsive } from '../../atoms/Layout/Responsive';
import { ArkCssBreakpoints } from '../../constants/ArkCssBreakpoints';
import { HeaderSideMenuTabs } from '../../constants/HeaderSideMenuTabs';
import { PageTypes } from '../../constants/Pages';
import { TOP_CATEGORIES } from '../../constants/TopCategories';
import { GameState } from '../../models/Enums';
import { Analytics } from '../../services/Analytics/Analytics';
import { UrlService } from '../../services/UrlService';
import { setSideMenuActivePage, setSideMenuOpened } from '../../store/ducks/layout';
import { Dropdown } from '../Dropdown/Dropdown';
import { LanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcher';

export const Footer = React.memo(() => {
    const [trackedImpression, _setTrackedImpression] = useState(false);
    const trackedImpressionRef = useRef(trackedImpression);
    const setTrackedImpression = (value) => {
        trackedImpressionRef.current = value;
        _setTrackedImpression(value);
    };
    const pageType = useSelector((state) => state.pageType);
    const currentLang = useSelector((state) => state.currentLang);
    const gameState = useSelector((state) => state.gameState);
    const activeGameNavigationTab = useSelector((state) => state.gamePageViewState.activeTab);
    const giftCardStep = useSelector((state) => state.giftCardStep);
    const categoryPromoData = useSelector((state) => state.categoryPromoData);
    const plusFeatureFlag = useSelector(({ arkConfig }) => arkConfig?.plusFeature?.isEnabled);
    const dispatch = useDispatch();
    const footerRef = useRef<HTMLDivElement>();
    const trackIfVisible = () => {
        const { current: trackingValue } = trackedImpressionRef;

        if (trackingValue) {
            return;
        }

        const el = footerRef.current;
        const html = document.body.parentElement;

        if (el) {
            const elR = el.getBoundingClientRect();

            if (elR.y + elR.height * 0.5 <= Math.abs(html.clientHeight)) {
                setTrackedImpression(true);
                Analytics.trackEvent(Analytics.general.footerImpression());
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', trackIfVisible);

        return () => {
            window.removeEventListener('scroll', trackIfVisible);
        };
    }, []);

    const handleSelectCategory = (item) => {
        const { link } = item;

        document.location.href = link[currentLang]
            ? UrlService.createURL(link[currentLang])
            : UrlService.createURL(link['en']);
    };
    let Resp;

    switch (pageType) {
        case PageTypes.Game: {
            if (gameState === GameState.PREROLL && activeGameNavigationTab === 'game') {
                Resp = (pr: any) => <React.Fragment {...pr} />;
            } else {
                Resp = (pr: any) => <Responsive minWidth={ArkCssBreakpoints.ARK_SMALL_DESKTOP} {...pr} />;
            }

            break;
        }

        default: {
            Resp = (pr: any) => {
                return <Responsive minWidth={0} {...pr} />;
            };
        }
    }

    const footerClassName = classNames(styles.footer, {
        [styles.footerGamepage]: pageType === PageTypes.Game,
        [styles.withStickyBtn]: pageType === PageTypes.GiftCard && giftCardStep === 1,
    });
    const yearString = `© ${new Date().getFullYear()} Arkadium`;
    let TOP_CATEGORIES_FILTERED = TOP_CATEGORIES;

    if (currentLang === 'fr') {
        TOP_CATEGORIES_FILTERED = TOP_CATEGORIES.filter((item) => item.link['fr'] !== '');
    }

    const PrivacyPolicyURL = UrlService.createURL('privacy-policy/');

    return (
        <Resp>
            <footer id="siteFooter" ref={footerRef} className={classNames(footerClassName, styles.footerWrapper)}>
                <Box className={styles.arkLogo}>
                    <a
                        href="https://corporate.arkadium.com/"
                        className={styles.logoLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Arkadium Logo"
                        data-element-description="footer-logo"
                    >
                        <ArkadiumFullLogo textInheritColor />
                    </a>
                </Box>
                <Box className={styles.socialLinks}>
                    <Box className={styles.socialLink}>
                        <a
                            onClick={() => {
                                Analytics.trackEvent(Analytics.newHome.footerSocialNav('instagram'));
                            }}
                            href="https://www.instagram.com/arkadiumgames/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Instagram"
                        >
                            <SocialIcon className={styles.footerSocialIcon} iconType={SocialIconType.INSTAGRAM} />
                        </a>
                    </Box>
                    <Box className={styles.socialLink}>
                        <a
                            onClick={() => {
                                Analytics.trackEvent(Analytics.newHome.footerSocialNav('facebook'));
                            }}
                            href="https://www.facebook.com/arkadium/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="Facebook"
                        >
                            <FacebookFooterIcon className={styles.footerSocialIcon} />
                        </a>
                    </Box>
                    <Box className={styles.socialLink}>
                        <a
                            onClick={() => {
                                Analytics.trackEvent(Analytics.newHome.footerSocialNav('linkedin'));
                            }}
                            href="https://www.linkedin.com/company/arkadium/"
                            target="_blank"
                            rel="noopener noreferrer"
                            aria-label="LinkedIn"
                        >
                            <LinkedInIcon className={styles.footerSocialIcon} />
                        </a>
                    </Box>
                </Box>
                <Box className={classNames(styles.footerListItem, styles.dropDownItem, styles.topCategoryDropdown)}>
                    <Dropdown
                        label={<I18nText keyName="TOP_CATEGORIES" />}
                        items={TOP_CATEGORIES_FILTERED}
                        handleSelect={handleSelectCategory}
                    />
                </Box>
                <Box className={styles.supportsBlock}>
                    <ul className={styles.footerList}>
                        <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                            <Button
                                noPadding
                                onClick={() => {
                                    dispatch(setSideMenuActivePage(HeaderSideMenuTabs.HELP_TAB));
                                    dispatch(setSideMenuOpened(true));
                                }}
                            >
                                <I18nText keyName="FOOTER_PLAYER_SUPPORT" />
                            </Button>
                        </li>
                        <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                            <a
                                href={`https://support.arkadium.com/${currentLang}/support/home/`}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <I18nText keyName="FOOTER_GAME_HELP_CENTER" />
                            </a>
                        </li>
                        <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                            <a
                                href={UrlService.createURL(
                                    // @todo decompose this.props.currentLang & this.props.dispatch
                                    currentLang === 'fr' ? 'notre-histoire/' : 'our-story/'
                                )}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <I18nText keyName="ABOUT_US_OUR_STORY" />
                            </a>
                        </li>
                        <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                            <button onClick={() => (window as any).__tcfapi('displayConsentUi', 2, function () {})}>
                                <I18nText keyName="COOKIE_BANNER_4" />
                            </button>
                        </li>
                    </ul>
                </Box>
                <Box className={classNames(styles.dropDownItem, styles.langSwitcher)}>
                    <LanguageSwitcher />
                </Box>
                <Box className={styles.corporateBlock}>
                    <ul className={styles.footerList}>
                        <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                            <a href="https://corporate.arkadium.com/" target="_blank" rel="noopener noreferrer">
                                <I18nText keyName="FOOTER_CORPORATE" />
                            </a>
                        </li>
                        <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                            <a href="https://corporate.arkadium.com/careers/" target="_blank" rel="noopener noreferrer">
                                <I18nText keyName="FOOTER_CAREERS" />
                            </a>
                        </li>
                        <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                            <a href="https://developers.arkadium.com/" target="_blank" rel="noopener noreferrer">
                                <I18nText keyName="FOOTER_DEVELOPERS" className={styles.noCapitalization} />
                            </a>
                        </li>
                        {currentLang !== 'fr' && (
                            <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                                <a
                                    href={UrlService.createURL(currentLang === 'fr' ? 'blog/' : 'blog/')}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <I18nText keyName="FOOTER_BLOG" />
                                </a>
                            </li>
                        )}
                    </ul>
                </Box>
                <Box className={styles.madeInNY}>
                    <MadeInNYIcon />
                </Box>
                <Box className={styles.legalBlock}>
                    <ul className={classNames(styles.footerList, styles.legalList)}>
                        <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                            <a
                                href={PrivacyPolicyURL}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-element-description="policy-link"
                            >
                                <I18nText keyName="FOOTER_PRIVACY_POLICY" />
                            </a>
                        </li>
                        {
                            categoryPromoData?.pdf?.url && (
                                <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                                    <a
                                        href={
                                            categoryPromoData?.pdf?.url
                                                ? categoryPromoData?.pdf.url
                                                : UrlService.createURL(`/sweepstakes_rules.pdf`, true)
                                        }
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        <I18nText keyName="FOOTER_SWEEPSTAKES_RULES" />
                                    </a>
                                </li>
                            )
                        }
                        <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                            <a
                                href={UrlService.createURL(plusFeatureFlag ? '/arkadium-player-agreement.pdf' : '/legacy-documents/arkadium-player-agreement.pdf', true)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <I18nText keyName="PLAYER_AGREEMENT" />
                            </a>
                        </li>
                        <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                            <a
                                href={UrlService.createURL(plusFeatureFlag ? '/arkadium-plus-agreement.pdf' : '/arkadium-advantage-agreement.pdf', true)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <I18nText keyName={plusFeatureFlag ? 'PLUS_SUBSCRIPTION_AGREEMENT' : 'SUBSCRIPTION_AGREEMENT'} />
                            </a>
                        </li>
                        <li className={classNames(styles.footerListItem, styles.animatedHover)}>
                            <a
                                href={UrlService.createURL('/site-map/', false, false, false)}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                <I18nText keyName="SITE_MAP" />
                            </a>
                        </li>
                    </ul>
                </Box>
                <Box className={styles.footerYearItem}>{yearString}</Box>
            </footer>
        </Resp>
    );
});
