import React, { FC, useEffect, useState } from 'react';

import { PlanDto } from '@arkadium/eagle-payments-api-client/dist/types/api/v1/dto/plan.dto';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styles from './PlusJoinBlock.css';
import { globalErrorHandler } from '../../../../../../../../utils/LogUtils';
import { Avatar, AvatarTypes, BorderTypes } from '../../../../../../../atoms/Avatar/Avatar';
import { ArkadiumPlusLogo } from "../../../../../../../atoms/Icons/Styleguide/ArkadiumPlusLogo";
import { ListCheckCircleIcon } from '../../../../../../../FigmaStyleguide/Icons/ListCheckCircleIcon';
import { AppInsightService } from '../../../../../../../services/AppInsight';
import PaymentService from '../../../../../../../services/PaymentService';
import { setSubscriptionPlans } from '../../../../../../../store/ducks/subscription/common';
import { SubscriptionActions } from '../../../PaymentAndSubscription';

type JoinBlockProps = {
  type: SubscriptionActions;
  isTrialAvailable: boolean;
};

const COLOR_CIRCLE_COLOR = '#FFFFFF';
const COLOR_CIRCLE_MAIN = '#12B76A';

export const PlusJoinBlock: FC<JoinBlockProps> = React.memo(({ type, isTrialAvailable }: JoinBlockProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const user = useSelector(({ user }) => user);
  const plansData = useSelector(({ subscriptionPlans }) => subscriptionPlans);
  const [_, setPlans] = useState<PlanDto[]>(plansData || []);
  const userAvatar = user?.avatar || user?.subscriberAvatar?.avatar || '';

  useEffect(() => {
    if (!plansData) {
      getSubscriptionPlans(dispatch, setPlans);
    }
  }, [plansData]);

  return (
    <div className={styles.container}>
      <div className={styles.avatar}>
        <Avatar
          image={userAvatar}
          selectable={false}
          selected={false}
          size={AvatarTypes.REGULAR_ADVANTAGE}
          onSelect={() => null}
          disabled
          border={borderMapping(type)}
          noFrame
        />
      </div>
      <div className={styles.text}>
        <h2 className={styles.title}>Join Arkadium Plus</h2>
        <div className={styles.description}>
          <ul className={styles.advantagesList}>
            {isTrialAvailable && (
              <li className={styles.listItem}>
                <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
                <span>{t('SELECT_SUBSCRIPTION.PLUS_THREE_DAY_TRIAL')}</span>
              </li>
            )}
            <li className={styles.listItem}>
              <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
              <span>{t('SELECT_SUBSCRIPTION.PLUS_FREE_ADS')}</span>
            </li>
            <li className={styles.listItem}>
              <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
              <span>{t('SELECT_SUBSCRIPTION.PLUS_SUBSCRIBER_ONLY_GAME')}</span>
            </li>
            {type === SubscriptionActions.RESTART && (
              <li className={styles.listItem}>
                <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
                <span>{t('SELECT_SUBSCRIPTION.PLUS_BONUS_GEMS')}</span>
              </li>
            )}
          </ul>
          <ul className={styles.advantagesList}>
            {type === SubscriptionActions.NONE && (
              <li className={styles.listItem}>
                <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
                <span>{t('SELECT_SUBSCRIPTION.PLUS_BONUS_GEMS')}</span>
              </li>
            )}
            <li className={styles.listItem}>
              <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
              <span>{t('SELECT_SUBSCRIPTION.PLUS_SAVE_PERCENT')}</span>
            </li>
            <li className={styles.listItem}>
              <ListCheckCircleIcon checkColor={COLOR_CIRCLE_COLOR} circleColor={COLOR_CIRCLE_MAIN} />
              <p>{t('SELECT_SUBSCRIPTION.PLUS_VIP_SUPPORT')}</p>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.logo}>
        <ArkadiumPlusLogo width={151} height={75} />
      </div>
    </div>
  )
    ;
});

const borderMapping = (type: SubscriptionActions) => {
  return {
    [SubscriptionActions.NONE]: BorderTypes.DEFAULT,
    [SubscriptionActions.STANDARD]: BorderTypes.GOLD,
    [SubscriptionActions.RENEW]: BorderTypes.GOLD,
    [SubscriptionActions.RESTART]: BorderTypes.DISABLED
  }[type];
};

export function getSubscriptionPlans(dispatch: any, callback?: any) {
  PaymentService.getPlans()
    .then((res) => {
      dispatch(setSubscriptionPlans([res]));

      if (callback) {
        callback(res);
      }
    })
    .catch((err) => {
      globalErrorHandler({ error: err, filename: 'JoinBlock.tsx', info: 'getSubscriptionPlans()' });
      AppInsightService.trackAppError(err, { data: 'getPlans()' });
    });
}
